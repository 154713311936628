// -- -- -- -- -- -- -- -- -- -- --
// Init the Slide Function

$( document ).ready(function() { 	
	
	$('.sticky-items .sprechzeiten-content').hide();
	$('.sticky-items .sprechzeiten-trigger').on( "click", function() {
	  $('.sticky-items .sprechzeiten-content').fadeIn( "slow", function() {
		// Animation complete
	  });
	});
	$('.sticky-items .sprechzeiten-content').on( "click", function() {
	  $('.sticky-items .sprechzeiten-content').fadeOut( "slow", function() {
		// Animation complete
	  });
	});
	
	$('#v-pills-tab button').on( "click", function() {
		//var child_top = $("#v-pills-tab").scrollTop() + $(this).offset().top;
	  //console.log(child_top);
	  //htmlStr = '<style>.block_kinderchirugie.block_kinderchirugie-leistungen .container .row .nav-pills:after{top:'+child_top+'px}</style>';
	  //$('.added-style').html(htmlStr);
	});
	
	$(window).scroll(function() {    
		var scroll = $(window).scrollTop();
		if (scroll >= 50) {
			$("#navbar-brand-container").addClass("scrolled-header");
		}else{
			$("#navbar-brand-container").removeClass("scrolled-header");
		}
	});
	
	setTimeout(function() { 
		$('.wsf-form button').append('<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9.5" cy="9.5" r="9.5" fill="#00446C"/><path d="M5.64645 12.6464C5.45118 12.8417 5.45118 13.1583 5.64645 13.3536C5.84171 13.5488 6.15829 13.5488 6.35355 13.3536L5.64645 12.6464ZM13.5 6C13.5 5.72386 13.2761 5.5 13 5.5L8.5 5.5C8.22386 5.5 8 5.72386 8 6C8 6.27614 8.22386 6.5 8.5 6.5L12.5 6.5L12.5 10.5C12.5 10.7761 12.7239 11 13 11C13.2761 11 13.5 10.7761 13.5 10.5L13.5 6ZM6.35355 13.3536L13.3536 6.35355L12.6464 5.64645L5.64645 12.6464L6.35355 13.3536Z" fill="white"/></svg>');
	}, 500);
	
	
	$('.newsslider').each(function( index ) {
		if ($(window).width() > 767) {
			$(this).slick({
				slidesToScroll: 1,
				prevArrow: $('#prev-arrow'),
				nextArrow: $('#next-arrow'),
				infinite: true,
				autoplay: false,
				autoplaySpeed: 2000,
				slidesToShow: 1.5,
				  infinite: false,
				  centerMode: true,
				  centerPadding: '0px',
			});
		}else{
			$(this).slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				prevArrow: $('#prev-arrow'),
				nextArrow: $('#next-arrow'),
				infinite: true,
				autoplay: false,
				autoplaySpeed: 2000,
			});
		}
	});
	if ($(window).width() > 600) {
	   var sWidth = $('.newsslider > div').outerWidth();
	   var cWidth = (sWidth / 3) * 2;
	   var nWidth = sWidth / 3;
	   setTimeout(function() {
		   $('.newsslider > div .slick-current').attr('style', 'width: '+cWidth+'px !important; transition: width 450ms ease;');
		   //$('.newsslider > div .slick-next').attr('style', 'width: '+nWidth+'px !important; transition: width 450ms ease;');
		   $('.newsslider > div .slick-current').next().attr('style', 'width: '+nWidth+'px !important; transition: width 450ms ease;'); 
	   }, 1000);
		   $('.newsslider').parent().find('.slider-arrow').on( "click", function() {
			setTimeout(function() {
				$('.newsslider > div .slick-current').attr('style', 'width: '+cWidth+'px !important; transition: width 450ms ease;');
				//$('.newsslider > div .slick-next').attr('style', 'width: '+nWidth+'px !important; transition: width 450ms ease;');
				$('.newsslider > div .slick-current').next().attr('style', 'width: '+nWidth+'px !important; transition: width 450ms ease;');
			}, 500);
		   });
	}
	$('.newsslider .slick-track').addClass('d-flex');
	$('.newsslider .slick-list').addClass('overflow-hidden');
	
	
	jQuery(function($) {
		function equalHeight(group) {
			//group.css('height','auto'); 
			var tallest = 0;
			group.each(function() {
			   var thisHeight = $(this).height();
			   if(thisHeight > tallest) {
				  tallest = thisHeight;
			   }
			});
			group.height(tallest);
		}
		
		if ($(window).width() > 767) {
		   equalHeight($(".equalheight"))
		}
		
		function equalMinHeight(group) {
			//group.css('height','auto'); 
			var tallest = 0;
			group.each(function() {
			   var thisHeight = $(this).height();
			   console.log(thisHeight);
			   if(thisHeight > tallest) {
				  tallest = thisHeight;
			   }
			});
			group.css('min-height' , ''+tallest+'px');
		}
		if ($(window).width() > 767) {
		   equalMinHeight($(".equalminheight"))
		}
	
	});
	
	//equalHeight($(".equalheight"));

	
}); 